import React, { useState, useEffect, useRef, useCallback } from "react";
import { HeroHighlightTextDemo } from "./HeroSectionTextAnimation/HeroHighlightTextDemo";
import "../../css/Hero.css";
import { useDropzone } from "react-dropzone"; // Import react-dropzone
import CommonButton from "../../Components/CommonButton";
import Footer from "../../Components/Footer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CreateImg from "../../assets/images/create.webp";
import uploadImage from "../../assets/images/uploadImage.png";
import uploadIcon from "../../assets/images/UploadToCloud.png";
// import { TimelineMax, SteppedEase } from "gsap/all";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

// import CompliancesSection from "../../Components/CompliancesSection";
import { extractPdfPagesFromFile } from "../../actions/extractPdfPagesFromFile";
import backButton from "../../assets/images/arrow_left.png";
import axios from "axios";
import { encode as base64_encode } from "base-64";
import { useSnackbar } from "notistack";
import validator from "validator";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomLoader1 from "../../Components/customLoader1";
import successGif from "../../assets/images/success.gif";
import { DialogTitle, Radio } from "@mui/material";
import ESignDetailDrawer from "../../Components/ESignDetailDrawer";
import { dataURLtoFile } from "../../actions/general";
import CustomersSection from "./CustomersSection";
import Demo from "./DemoBanner/Demo";
import ESignValueSection from "./ESignValueSection";
import { GoogleGeminiEffectDemo } from "./HowItWorkSection/GoogleGeminiEffectDemo.tsx";
import NewFooter from "../../Components/NewFooter.tsx";
import PricingCardSection from "./PricingCardSection/index.js";
import { uploadSingleFileByPresignedUrl } from "../../actions/s3.actions.js";
import TopNavBar from "../../Components/TopNavBar.tsx";
import { Close } from "@mui/icons-material";
import UpgradePricing from "../../Components/UpgradePricing";
import TestimonialSection from "./TestimonialSection/index.js";
import DealsStickyBar from "../../Components/DealsStickyBar/index.tsx";
import FaqsSection from "./ESignFaqs/index.tsx";

const nodeBaseUrl = process.env.REACT_APP_NODE_APIS_BASE_URL;
const pythonBaseUrl = process.env.REACT_APP_AI_API_BASE_URL;
const pythonApiToken = process.env.REACT_APP_AI_API_TOKEN;
const legittBaseUrl = process.env.REACT_APP_LEGITT_BASE_URL;

export default function NewLandingPageHeroSection() {
  const { enqueueSnackbar } = useSnackbar();
  const [isFormValid, setIsFormValid] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showEmailDialog, setShowEmailDialog] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [signatories_category, setSignatories_category] = React.useState("self_only");
  const [user, setUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [mydocument, setMydocument] = React.useState(null);
  const [showUpgradePopup, setShowUpgradePopup] = React.useState(false);
  const [stepsCompleted, setStepsCompleted] = React.useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [keyFacts, setKeyFacts] = useState([]);
  const [currentKeyFactIndex, setCurrentKeyFactIndex] = useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const [showInput, setShowInput] = useState(false);
  const [isStickyBarVisible, setIsStickyBarVisible] = useState(true);

  const inputRef = useRef(null);

  const triggerFileUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleButtonClick = () => {
    setShowInput(true);
  };
  const handleResetClick = () => {
    setShowInput(false);
  };
  const handleFaqAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const exampleKeyFacts = [
    "Legitt AI significantly streamlines contract management processes, saving time and reducing manual effort.",
    "The platform's AI-driven analysis improves contract accuracy and minimizes legal and compliance risks.",
    "By automating routine tasks, Legitt AI reduces operational costs associated with contract handling.",
    "It provides valuable insights through advanced analytics, aiding in informed decision-making and strategy development.",
    "Legitt AI's flexible architecture easily adapts to growing business needs, ensuring scalability for various enterprise sizes.",
  ];

  const onClose = () => {
    setOpen(false);
    console.log("close");
  };

  const handleContainerClick = () => {
    setIsChecked(!isChecked);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleFileSelect(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    },
    maxSize: 5 * 1024 * 1024,
  });

  const handleClose = () => {
    setShowEmailDialog(false);
    if (user && user.userId && mydocument && mydocument.documentId) {
      resetDetails();
    }
  };

  const handleFileSelect = async (myFile) => {
    if (myFile.name && myFile.name.includes(".docx")) {
      setIsLoading(true);
      setLoadingMessage("Converting word to pdf.");

      let formData = new FormData();
      formData.append("file", myFile);

      await axios
        .post(`${nodeBaseUrl}/api/convertWordToPdf`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "User-origin": window.location.href,
          },
        })
        .then((res) => {
          console.log(res);
          let data = res.data;
          if (data.error) {
            enqueueSnackbar(data.message, {
              variant: "error",
              autoHideDuration: 3000,
            });
            setIsLoading(false);
            setLoadingMessage("");
            return;
          } else {
            let filename = "";
            filename = myFile.name.replace(".docx", ".pdf");

            // console.log({ pdfFile });
            let pdfFile = "data:application/pdf;base64," + data.pdfFile;
            pdfFile = dataURLtoFile(pdfFile, filename);
            myFile = pdfFile;
            setIsLoading(false);
            setLoadingMessage("");
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Internal server error!", {
            variant: "error",
            autoHideDuration: 3000,
          });
          setIsLoading(false);
          setLoadingMessage("");
          return;
        });
    }
    setSelectedFile(myFile);
    extractPdfPagesFromFile(myFile, "pdf-pages-section", setLoadingPdf, enqueueSnackbar);
  };

  const resetDetails = () => {
    setSelectedFile(null);
    setEmail("");
    setUser(null);
    setMydocument(null);
    setLoadingPdf(false);
    setIsLoading(false);
    setShowEmailDialog(false);
    setStepsCompleted(0);
    setFirstName("");
    setLastName("");
  };

  const handleCreateUser = async () => {
    let userId = null;
    let userToken = null;
    let existingUser = false;

    await axios
      .post(
        `${nodeBaseUrl}/apis/auth/signupnew`,
        {
          email: email,
          captcha: "verified",
          firstName: firstName || "",
          lastName: lastName || "",
        },
        {
          headers: {
            "User-origin": window.location.href,
          },
        }
      )
      .then((response) => {
        console.log(response);
        let data = response.data;
        if (data.userId) {
          setUser(data);
          userId = data.userId;
          userToken = data.token;
          existingUser = data.existingUser || false;
        } else {
          enqueueSnackbar("Invalid email", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        const resError = error.response?.data;
        enqueueSnackbar(resError?.message || "Invalid email", {
          variant: "error",
        });
      });

    if (userId && userToken) {
      // await axios
      //   .post(
      //     `${nodeBaseUrl}/profile/addUserProfile`,
      //     {
      //       userId: userId,
      //       email: email,
      //       firstName: firstName,
      //       lastName: lastName,
      //     },
      //     {
      //       headers: {
      //         "User-origin": window.location.href,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      return { userId, userToken, existingUser };
    } else {
      return null;
    }
  };

  const handleUploadFileToS3 = async (userId) => {
    let fileUploaded = null;
    let userID = user ? user.userId : userId;

    if (!selectedFile) {
      enqueueSnackbar("Please upload a file", {
        variant: "error",
      });
      return fileUploaded;
    }

    let formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("userId", userID);
    formData.append("file_type", "public");

    await axios
      .post(`${nodeBaseUrl}/uploadFileToAWS_S3`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "User-origin": window.location.href,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data && res.data.s3_file_url && res.data.s3_file_key) {
          fileUploaded = res.data;
        } else {
          enqueueSnackbar("Failed to upload file", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        const resError = err.response?.data;
        enqueueSnackbar(resError?.message || "Failed to upload file", {
          variant: "error",
        });
      });

    return fileUploaded;
  };

  const handleUploadFileToS3v2 = async (userId) => {
    let fileUploaded = null;
    let userID = user ? user.userId : userId;

    if (!selectedFile) {
      enqueueSnackbar("Please upload a file", {
        variant: "error",
      });
      return fileUploaded;
    }

    try {
      const fileUploadRes = await uploadSingleFileByPresignedUrl(userID, selectedFile);

      if (!fileUploadRes || !fileUploadRes.s3_file_key) {
        enqueueSnackbar("Failed to upload file", {
          variant: "error",
        });
        return fileUploaded;
      }
      console.log({ fileUploadRes });
      fileUploaded = fileUploadRes;
      return fileUploaded;
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Failed to upload file", {
        variant: "error",
      });
    }
  };

  const handleCreateDocument = async (userId, userToken) => {
    let documentId = null;
    let uploadFileRes = null;
    let documentcontent = [];
    let userID = user ? user.userId : userId;
    let documentName = selectedFile.name.replace(".pdf", "");

    let docDiv = document.getElementById("pdf-pages-section");
    let docDivChildren = docDiv.children;
    if (!docDivChildren || docDivChildren.length === 0) {
      return { documentId, uploadFileRes };
    }
    for (let i = 0; i < docDivChildren.length; i++) {
      documentcontent.push(docDivChildren[i].outerHTML);
    }
    documentcontent = JSON.stringify(documentcontent);

    uploadFileRes = await handleUploadFileToS3v2(userID);
    if (!uploadFileRes) {
      return { documentId, uploadFileRes };
    }

    // self_only, multiple, others_only
    const doc_additional_info = {
      signatories_category: signatories_category,
    };

    let formData = new FormData();
    formData.append("filename", documentName);
    formData.append("documentUrl", uploadFileRes.s3_file_url);
    formData.append("documentKey", uploadFileRes.s3_file_key);
    formData.append("userId", userID);
    formData.append("doc_status", "draft");
    formData.append("documentContent", "");
    formData.append("additionalInformation", JSON.stringify(doc_additional_info));
    formData.append("document_source", "esign");

    await axios
      .post(`${nodeBaseUrl}/apis/document/documentcreatefile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user ? user.token : userToken}`,
          "User-origin": window.location.href,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data && res.data.documentId) {
          setMydocument(res.data);
          documentId = res.data.documentId;
        } else {
          enqueueSnackbar("Failed to create document", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        const resError = err.response?.data;
        enqueueSnackbar(resError?.message || "Failed to create document", {
          variant: "error",
        });
      });

    return { documentId, uploadFileRes };
  };

  const handleInitiateTextExtraction = async (documentId, s3FileKey) => {
    let textExtracted = false;

    let formData = new FormData();
    formData.append("documentId", documentId);
    formData.append("s3_file_key", s3FileKey);
    formData.append(
      "callback_url",
      `${nodeBaseUrl}/api/document-callbacks/updateDocumentFileContentViaOcr`
    );
    await axios
      .post(`${pythonBaseUrl}/fastapi/v1/pdf-to-text`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${pythonApiToken}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          textExtracted = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return textExtracted;
  };

  const convertDocumentToPages = async (userId, documentId, s3_file_key) => {
    let documentPages = null;

    let formData = new FormData();
    formData.append("external_user_id", userId);
    formData.append("document_id", documentId);
    formData.append("s3_file_key", s3_file_key);
    await axios
      .post(`${pythonBaseUrl}/fastapi/v1/extract_images_of_file`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${pythonApiToken}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data && res.data.success) {
          documentPages = res.data?.data?.pages;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return documentPages;
  };

  const saveDocumentPages = async (userDetails, documentId, documentPages) => {
    let pagesSaved = false;

    await axios
      .post(
        `${nodeBaseUrl}/apis/document/createUpdateUserDocumentDetails/${documentId}`,
        {
          userId: userDetails.userId,
          pages: documentPages,
          documentFields: null,
        },
        {
          headers: {
            Authorization: `Bearer ${userDetails.userToken}`,
            "User-origin": window.location.href,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data && res.data.error === false) {
          pagesSaved = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return pagesSaved;
  };

  const handleSubmit = async (event) => {
    onClose();
    event.preventDefault();
    console.log({ email });

    const emailValid = validator.isEmail(email);
    if (!email || email === "") {
      enqueueSnackbar("Please enter your email", {
        variant: "error",
      });
      return;
    } else if (!emailValid) {
      console.log("invalid email");
      enqueueSnackbar("Please enter a valid email", {
        variant: "error",
      });
      return;
    }

    if (!firstName || firstName === "") {
      enqueueSnackbar("Please enter your first name", {
        variant: "error",
      });
      return;
    } else if (!lastName || lastName === "") {
      enqueueSnackbar("Please enter your last name", {
        variant: "error",
      });
      return;
    }

    if (loadingPdf) {
      enqueueSnackbar("Please wait for the PDF to load", {
        variant: "info",
      });
      return;
    }

    setIsLoading(true);
    setKeyFacts(exampleKeyFacts);
    setCurrentKeyFactIndex(0);

    let userDetails = await handleCreateUser();
    if (!userDetails) {
      setIsLoading(false);

      return;
    }
    setStepsCompleted(2);
    let { documentId, uploadFileRes } = await handleCreateDocument(
      userDetails.userId,
      userDetails.userToken
    );
    setStepsCompleted(3);
    if (!documentId || !uploadFileRes || !uploadFileRes.s3_file_key) {
      setIsLoading(false);
      return;
    }
    let documentPages = await convertDocumentToPages(
      userDetails.userId,
      documentId,
      uploadFileRes.s3_file_key
    );
    if (!documentPages || documentPages.length === 0) {
      enqueueSnackbar("Failed to extract document pages", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }

    let [textExtracted, pagesSaved] = await Promise.all([
      handleInitiateTextExtraction(documentId, uploadFileRes.s3_file_key),
      saveDocumentPages(userDetails, documentId, documentPages),
    ]);

    // let  = await handleInitiateTextExtraction(documentId, uploadFileRes.s3_file_key);
    // const  = await saveDocumentPages(userDetails, documentId, documentPages);
    if (!pagesSaved) {
      enqueueSnackbar("Failed to save document", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }

    setStepsCompleted(4);

    setIsLoading(false);
    setShowEmailDialog(true);
    let anchorTag = document.createElement("a");
    let doc_url = `${legittBaseUrl}/${email.split("@")[0]}/text-editor/${base64_encode(
      documentId
    )}${!userDetails.existingUser ? `?access_token=${userDetails.userToken}` : ""}`;
    anchorTag.setAttribute("href", doc_url);
    anchorTag.setAttribute("target", "_blank");
    anchorTag.click();

    // resetDetails();
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value ? value.trim() : "");
  };

  const showNextKeyFact = () => {
    if (currentKeyFactIndex < keyFacts.length - 1) {
      setCurrentKeyFactIndex(currentKeyFactIndex + 1);
    } else {
      // Reset to the first key fact when reaching the end
      setCurrentKeyFactIndex(0);
    }
  };

  // Use a timer to automatically show the next key fact (adjust the delay as needed)
  useEffect(() => {
    const timer = setTimeout(() => {
      showNextKeyFact();
    }, 2000); // 2 seconds delay, adjust as needed

    // Clear the timer when the component unmounts or when all key facts have been displayed
    return () => clearTimeout(timer);
  }, [currentKeyFactIndex, keyFacts]);

  useEffect(() => {
    // Check if all fields are filled
    const isValid = firstName.trim() !== "" && lastName.trim() !== "" && email.trim() !== "";
    setIsFormValid(isValid);
  }, [firstName, lastName, email]);
  useEffect(() => {
    if (isLoading) {
      // Disable scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      document.body.style.overflow = "auto";
    }

    // Cleanup function to re-enable scrolling when the component is unmounted
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isLoading]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const hash = window.location.hash;
      if (hash) {
        const id = hash.substring(1); // Remove the '#' from the hash
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, []);

  const handleCloseStickyBar = () => {
    setIsStickyBarVisible(false);
  };

  return (
    <div>
      <section className="bg-white dark:bg-gray-900 mb-[0px]">
        {selectedFile === null ? (
          <>
            {isStickyBarVisible && <DealsStickyBar onClose={handleCloseStickyBar} />}

            <TopNavBar isStickyBarClosed={!isStickyBarVisible} />
            <div className="text-center bg-gray-100 pb-5 mb-10">
              <HeroHighlightTextDemo />
            </div>
            <div className="mt-[20px] pb-10">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="bg-gray-50  py-[20px] cursor-pointer flex justify-center items-center flex-col border-2 border-dashed border-gray-300 rounded-[20px] text-center  hover:bg-gray-100 transition-all w-[70%]">
                  <div
                    id="upload-file-container"
                    {...getRootProps({
                      className: "dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="flex justify-center items-center flex-col w-[100%]">
                      <div className=" mb-[5px]">
                        <p className="text-gray-900 text-sm mb-1">
                          Please upload the document you want to review.
                        </p>
                        <p className="text-gray-600 text-xs md:text-sm mb-4 text-center">
                          Only <span className="font-semibold">.pdf</span> and{" "}
                          <span className="font-semibold">.docx</span> files are allowed.
                        </p>
                      </div>
                      {/* <div className="supportText mb-[10px]">
                        For other formats, files over 5MB, or more than 20
                        pages,
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation(); // Stop event propagation
                            setShowUpgradePopup(true);
                          }}
                          className="cursor-pointer text-[#06526D] mx-1"
                        >
                          upgrade
                        </span>
                        or
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.location.href = "mailto:info@legittai.com";
                          }}
                          className="cursor-pointer text-[#06526D] mx-1"
                        >
                          contact us
                        </span>
                      </div> */}
                      <div className="flex flex-col justify-center items-center">
                        <img src={uploadIcon} className="dragDropIcon" />

                        <div className="text-gray-700 font-medium mt-2">Drag & Drop</div>
                        <div className="text-gray-500 text-sm mt-2 mb-2">OR</div>
                        <div>
                          <CommonButton
                            label="Select File"
                            size="-medium"
                            variant="contained"
                            style={{ backgroundColor: "#06526D" }}
                          />
                        </div>
                        <p className="text-gray-500 text-xs mt-2">Max. size (5.00 MB)</p>
                      </div>
                      {/* Start your 7days free trail */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <CompliancesSection /> */}
            </div>

            <CustomersSection />
            {/* Ravi B 17 Dec 24 comment start */}
            {/* <section id="eSignPricing">
              <PricingCardSection />
            </section> */}
            {/* Ravi B 17 Dec 24 comment end */}
            {/* <CompliancesSection /> */}
            <Demo />
            <ESignValueSection />
            {/* <GoogleGeminiEffectDemo /> */}
            <TestimonialSection />

            {/* <div className="faQContainer">
              <div className="faQHeader">FAQs</div>
              <span
                style={{
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.5)",
                }}
              >
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleFaqAccordionChange("panel1")}
                  style={{
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                  className="faqs-accoridion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography
                      style={{
                        color: "#000000",
                      }}
                    >
                      How do I upload a document to the eSign application?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        color: "#000000",

                        textAlign: "left",
                        fontWeigth: "400px",
                      }}
                    >
                      To upload a document, first, sign in to your account. Once
                      you access the dashboard, you will notice an option for
                      document upload. Select this feature to proceed. You then
                      have the flexibility to either upload a document from your
                      device or explore and choose from our extensive library of
                      thousands of templates available for your convenience.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleFaqAccordionChange("panel2")}
                  style={{
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                  className="faqs-accoridion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography
                      style={{
                        color: "#000000",
                      }}
                    >
                      Can I use the eSign application's templates for my
                      documents?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        color: "#000000",
                        fontSize: "14px",
                        textAlign: "left",
                        fontWeigth: "400px",
                      }}
                    >
                      Certainly! To streamline your document creation process,
                      we offer an extensive selection of templates. Simply
                      select the one that best fits your requirements and
                      customize it to meet your specific needs. This feature is
                      designed to make the document creation both efficient and
                      user-friendly.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleFaqAccordionChange("panel3")}
                  style={{
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                  className="faqs-accoridion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography
                      style={{
                        color: "#000000",
                      }}
                    >
                      How do I add signatories to my document?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        color: "#000000",

                        textAlign: "left",
                        fontWeigth: "400px",
                      }}
                    >
                      Once you have uploaded your document, you can designate
                      the signatories by entering their email addresses or
                      names. Following this, our eSign application will
                      seamlessly and automatically dispatch notifications to
                      these individuals, prompting them to sign the document.
                      This process ensures a smooth and efficient signing
                      experience for all parties involved.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleFaqAccordionChange("panel4")}
                  style={{
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                  className="faqs-accoridion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                  >
                    <Typography
                      style={{
                        color: "#000000",
                      }}
                    >
                      What happens if a signatory doesn't sign the document
                      promptly?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        color: "#000000",

                        textAlign: "left",
                        fontWeigth: "400px",
                      }}
                    >
                      Our eSign application efficiently manages the signing
                      process by sending automated reminders to signatories
                      until they finalize their signatures. This feature is
                      designed to ensure that your documents are signed promptly
                      and without delay, enhancing the overall efficiency of the
                      document completion process.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleFaqAccordionChange("panel5")}
                  style={{
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                  className="faqs-accoridion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                  >
                    <Typography
                      style={{
                        color: "#000000",
                      }}
                    >
                      Tell me more about the "LegittAI" AI functionalities.
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        color: "#000000",

                        textAlign: "left",
                        fontWeigth: "400px",
                      }}
                    >
                      Legitt AI, an innovative platform, offers a suite of
                      features designed to streamline business operations. It
                      boasts advanced AI integration, enabling smart contract
                      management and decision-making automation. The platform is
                      enhanced with robust security protocols, being ISO 27001,
                      GDPR, and SOC 2 Type 2 compliant. Its user-friendly
                      interface facilitates seamless collaboration among team
                      members. Legitt AI's document management system is highly
                      efficient, offering an extensive library of templates and
                      a sophisticated eSign application for quick document
                      turnover. Additionally, it includes automated reminders to
                      expedite the signing process, ensuring timely completion
                      of tasks. This combination of AI and security makes Legitt
                      AI a comprehensive solution for modern businesses.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleFaqAccordionChange("panel6")}
                  style={{
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                  className="faqs-accoridion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6d-content"
                    id="panel6d-header"
                  >
                    <Typography
                      style={{
                        color: "#000000",
                      }}
                    >
                      How can I access the "LegittAI" functionality in the eSign
                      application?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        color: "#000000",

                        textAlign: "left",
                        fontWeigth: "400px",
                      }}
                    >
                      The "LegittAI" feature is readily accessible during your
                      document editing process. While working on a document, you
                      can easily tap on the "LegittAI" button. This action
                      activates the system to offer valuable insights and
                      practical suggestions, designed to enhance the quality and
                      effectiveness of your document.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel7"}
                  onChange={handleFaqAccordionChange("panel7")}
                  style={{
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                  className="faqs-accoridion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7d-content"
                    id="panel7d-header"
                  >
                    <Typography
                      style={{
                        color: "#000000",
                      }}
                    >
                      Is my data and documents secure within the eSign
                      application?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        color: "#000000",

                        textAlign: "left",
                        fontWeigth: "400px",
                      }}
                    >
                      Absolutely, safeguarding your data and documents is our
                      top priority. Our application employs robust encryption
                      and adheres to industry-leading best practices, ensuring
                      comprehensive protection of your information. We are fully
                      compliant with GDPR, ISO 27001, and SOC 2 standards,
                      further bolstering our commitment to data security.
                      Additionally, our use of AWS Cryptography signifies our
                      dedication to utilizing advanced security technologies.
                      For a detailed understanding of our security protocols and
                      measures, we invite you to review our comprehensive
                      privacy and security policy.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === "panel8"}
                  onChange={handleFaqAccordionChange("panel8")}
                  style={{
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                  className="faqs-accoridion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8d-content"
                    id="panel8d-header"
                  >
                    <Typography
                      style={{
                        color: "#000000",
                      }}
                    >
                      How do I track the status of my documents and signatures?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        color: "#000000",

                        textAlign: "left",
                        fontWeigth: "400px",
                      }}
                    >
                      Effortlessly monitor the progress of your documents
                      through our user-friendly dashboard. This feature provides
                      real-time updates on each document's status, clearly
                      indicating which signatories have completed their
                      signatures and which ones remain pending. Additionally,
                      you will receive timely notifications regarding the status
                      of your documents, keeping you informed every step of the
                      way and ensuring you're always up-to-date on the signing
                      process. This comprehensive tracking system is designed
                      for your convenience and peace of mind.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </span>
            </div> */}
            <div id="esign-pricing">
              <PricingCardSection />
            </div>
            <FaqsSection />
            <NewFooter />
          </>
        ) : null}
        <div className={`${selectedFile ? "flex bg-[#FAFAFA]" : "hidden"}`}>
          <div className="pdf-pages-section-container">
            <div className="selected-file-name-text">
              <div>
                <button
                  onClick={() => {
                    resetDetails();
                  }}
                >
                  <img src={backButton} alt="Back" className="w-8 h-8 mr-5" />
                </button>
              </div>
              <div>
                {selectedFile ? (
                  <div className="selected-file-name-input-field">{selectedFile.name}</div>
                ) : null}
              </div>
            </div>
            <div id="pdf-pages-section"></div>
          </div>
          <div className="right-container-pdf-view">
            <div className="right-container-pdf-view-header">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="signatories-category"
                  value={signatories_category}
                  style={{
                    width: "100%",
                    display: "flex",

                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                  onChange={(e) => {
                    setSignatories_category(e.target.value);
                  }}
                >
                  <div className="self-container">
                    <div className="flex items-center justify-start gap-3 w-full">
                      <div className="self-container-icon-container">
                        <img src={require("../../assets/icons/self-2.png")} className="self-icon" />
                      </div>
                      <div className="self-sign-text">
                        <FormControlLabel
                          value="self_only"
                          control={<Radio name="signatories-category" />}
                          label="Only I need to Sign"
                          labelPlacement="start"
                          // disabled={!allowDocumentEdit}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="self-other-container ">
                    <div className="flex items-center justify-start gap-3 w-full">
                      <div className="self-container-icon-container">
                        <img
                          src={require("../../assets/icons/self-other-2.png")}
                          className="self-icon"
                        />
                      </div>
                      <div className="self-sign-text">
                        <FormControlLabel
                          value="multiple"
                          control={<Radio name="signatories-category" />}
                          label="Others need to Sign too"
                          // disabled={!allowDocumentEdit}
                          labelPlacement="start"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="other-container">
                    <div className="flex items-center justify-start gap-3 w-full">
                      <div className="self-container-icon-container">
                        <img
                          src={require("../../assets/icons/other-2.png")}
                          className="self-icon"
                        />
                      </div>
                      <div className="self-sign-text">
                        <FormControlLabel
                          value="others_only"
                          control={<Radio name="signatories-category" />}
                          labelPlacement="start"
                          label="Send to others only (I won't Sign)"
                          // disabled={!allowDocumentEdit}
                        />
                      </div>
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>

              <div className="information-container">
                <div className="information-container-title">From:</div>
                <div className="email-container">
                  {/* <Input
                  size="large"
                  placeholder="Name"
                  prefix={<UserOutlined />}
                /> */}
                  <div className="d-flex gap-3">
                    <TextField
                      className="emailInput"
                      label="First Name"
                      required
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      value={firstName}
                      variant="outlined"
                      disabled={isLoading || loadingPdf}
                    />
                    <TextField
                      className="emailInput"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      label="Last Name"
                      value={lastName}
                      required
                      variant="outlined"
                      disabled={isLoading || loadingPdf}
                    />
                  </div>
                  <TextField
                    className="emailInput"
                    label="Email Address"
                    required
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    disabled={isLoading || loadingPdf}
                  />
                  {/* <Input
                  size="large"
                  placeholder="Email Address"
                  prefix={<MailOutlined />}
                /> */}
                </div>
                {/* 
              <div className="helper-text-for-mail-input">
                Give your name and email address to let others know who you are.
              </div> */}
              </div>
              <div className="Button-container">
                <CommonButton
                  label="Cancel"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    resetDetails();
                  }}
                />
                <CommonButton
                  label="Next"
                  size="extraLarge"
                  variant="contained-variant"
                  onClick={handleSubmit}
                  disabled={!isFormValid || isLoading || loadingPdf}
                />
              </div>
            </div>
          </div>
          <div className="esign-dawer-container">
            <ESignDetailDrawer
              resetDetails={resetDetails}
              handleContainerClick={handleContainerClick}
              handleClose={handleClose}
              showEmailDialog={showEmailDialog}
              setShowEmailDialog={setShowEmailDialog}
              setStepsCompleted={setStepsCompleted}
              setMydocument={setMydocument}
              mydocument={mydocument}
              user={user}
              setUser={setUser}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              loadingPdf={loadingPdf}
              setLoadingPdf={setLoadingPdf}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              signatories_category={signatories_category}
              setSignatories_category={setSignatories_category}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handleCreateUser={handleCreateUser}
              handleUploadFileToS3v2={handleUploadFileToS3v2}
              handleCreateDocument={handleCreateDocument}
              handleInitiateTextExtraction={handleInitiateTextExtraction}
              enqueueSnackbar={enqueueSnackbar}
              stepsCompleted={stepsCompleted}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              handleEmailChange={handleEmailChange}
              handleSubmit={handleSubmit}
              onClose={onClose}
              open={open}
              setOpen={setOpen}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="text-center loader-main-container-document-uploading">
            <div>
              <CustomLoader1 />

              {loadingMessage ? (
                <p class="my-4 loader-text">{loadingMessage}</p>
              ) : (
                <React.Fragment>
                  <p class="my-4 loader-text">Preparing document for signature...</p>
                  <div className="key-facts-container">
                    <div>{keyFacts[currentKeyFactIndex]}</div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        ) : null}

        {/* Dialog popup for email */}
        <Dialog
          // onClose={handleClose}
          className="uploadDialogContainer"
          aria-labelledby="customized-dialog-title"
          open={showEmailDialog}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="dialogContent">
            {!(user && user.token && mydocument && mydocument.documentId) ? (
              <div>
                <div className="popupEmailHeader">
                  <div className="emailAddressHeader">Always free. No credit card required.</div>
                </div>
                <form onSubmit={handleSubmit}>
                  <TextField
                    className="emailInput"
                    label="Email Address"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    disabled={isLoading || loadingPdf}
                  />

                  {isLoading ? (
                    <div className="text-center">
                      <CustomLoader1 />
                    </div>
                  ) : null}

                  <div className="submitButtonPopup">
                    <CommonButton
                      onClick={handleSubmit}
                      label="Submit"
                      size="-medium"
                      variant="contained"
                      // className="mx-4"
                      type="submit"
                      disabled={isLoading || loadingPdf}
                    />
                  </div>
                </form>
              </div>
            ) : (
              <div>
                {/* <div className="successfullContainer">
                  <div className="viewDocumentText">
                    <a
                      style={{
                        textDecoration: "underline",
                      }}
                      target="_blank"
                      href={`${legittBaseUrl}/${
                        email.split("@")[0]
                      }/text-editor/${base64_encode(mydocument.documentId)}${
                        !user.existingUser ? `?access_token=${user.token}` : ""
                      }`}
                    >
                      {" "}
                      Click here to sign & view
                    </a>
                  </div>
                  <div className="gifContainer">
                    <img src={successGif} className="successGif" />
                  </div>
                  <div className="successText">Successfully uploaded!</div>
                </div> */}
                <div className="email-popup-main-upgrade-container">
                  <div className="email-popup-upgrade-section">
                    <div className="email-popup-upgrade-heading"></div>
                    <div className="email-popup-upgrade-points-container">
                      <div className="email-popup-upgrade-point edit-document-video">
                        <iframe
                          src="https://lottie.host/embed/b05b501d-a9c4-4d5b-a0e4-7ad3ea49c333/bmsag4UNoU.json"
                          frameBorder="0"
                          allowFullScreen
                          width={230}
                          height={230}
                          title="Lottie Animation"
                        ></iframe>
                      </div>
                      <div>
                        <div className="document-created-text">Your document is ready to sign</div>
                        <div className="document-create-helper-text">
                          (Looks like your popup blocker is enabled)
                        </div>
                        <div className="document-create-helper-text">
                          <span
                            className="document-link"
                            onClick={() => {
                              window.open(
                                `${legittBaseUrl}/${
                                  email.split("@")[0]
                                }/text-editor/${base64_encode(mydocument.documentId)}${
                                  !user.existingUser ? `?access_token=${user.token}` : ""
                                }`,
                                "_blank"
                              );
                            }}
                          >
                            Click here
                          </span>{" "}
                          to open the document
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </section>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "1000px",
            borderRadius: "20px",
          },
        }}
        open={showUpgradePopup}
        onClose={() => setShowUpgradePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "16px",
            padding: "10px 20px",
          }}
        >
          <div className="">Upgrade to access more features</div>
          <div className="">
            <Close onClick={() => setShowUpgradePopup(false)} className="cursor-pointer" />
          </div>
        </DialogTitle>
        <DialogContent>
          <UpgradePricing />
        </DialogContent>
      </Dialog>
    </div>
  );
}
